import React, { useState } from 'react'
import s from 'styled-components'
import swal from 'sweetalert'

import { Modal, ModalContent, ModalHeader } from './index'
import Loading from '../loading'
import Button from '../form/buttons'
import ModalHandler from '../../utils/modal'
import DragNDropFile from './drag-drop-file'

const Body = s.div`
  width: 100%;
  padding: 20px;
  text-align: justify;
`

const Table = s.table`
  width: 100%;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 40px;

  th, tr, td {
    border: 1px solid;
  }
`

const ALink = s.a`
   color: blue;
`

const style1 = { textAlign: 'right', borderTop: '1px solid #E8E8E8' }
const handleClose = (id, onClose) => {
  return () => {
    ModalHandler.closeModal(id)
    if (onClose) {
      onClose()
    }
  }
}

const ImportDetail = ({ lang, locale }) => {
  if (lang === 'EN') {
    return (
      <div>
        <p>{locale.IMPORT_INVITATION_DETAIL}</p>
        <p>
          {locale.IMPORT_INVITATION_DETAIL_NEXT} &nbsp;
          <ALink href={`${process.env.XLSX_TEMPLATE}`} target="_blank">
            {locale.XLSX_TEMPLATE}
          </ALink>
          &nbsp;
          {locale.AND} &nbsp;
          <ALink href={`${process.env.CSV_TEMPLATE}`} target="_blank">
            {locale.CSV_TEMPLATE}
          </ALink>
          {locale.FULL_STOP}
        </p>
      </div>
    )
  } else {
    return (
      <div>
        <p>{locale.IMPORT_INVITATION_DETAIL}</p>
        <p>
          {locale.EXAMPLE_TEMPLATE} &nbsp;
          <ALink href={`${process.env.XLSX_TEMPLATE}`} target="_blank">
            {locale.XLSX}
          </ALink>
          <br />
          {locale.EXAMPLE_TEMPLATE} &nbsp;
          <ALink href={`${process.env.CSV_TEMPLATE}`} target="_blank">
            {locale.CSV}
          </ALink>
        </p>
        <p>{locale.IMPORT_INVITATION_DETAIL_NEXT}</p>
      </div>
    )
  }
}

const DataTemplate = () => (
  <div>
    <Table>
      <tbody>
        <tr>
          <th>NAME</th>
          <th>EMAIL</th>
          <th>MESSAGE</th>
          <th>LANGUAGE</th>
        </tr>
        <tr>
          <td>John Doe</td>
          <td>John@invitree.me</td>
          <td>We would like to …</td>
          <td>EN</td>
        </tr>
        <tr>
          <td>คุณอาทิตย์</td>
          <td>arthid@gmail.com</td>
          <td>เชิญร่วมงาน ...</td>
          <td>TH</td>
        </tr>
      </tbody>
    </Table>
  </div>
)

const DragNDropShowFileName = ({ fileName }) => {
  if (!fileName || fileName.length === 0) {
    return <p style={{ color: '#E8E8E8', fontSize: 36 }}>Drag file here</p>
  } else {
    return (
      <p style={{ color: '#E8E8E8', fontSize: 24 }}>
        {fileName}
        <br />
        {'(Drag file here to replace)'}
      </p>
    )
  }
}

export const ModalClose = ({
  id,
  onSubmit,
  onClose,
  textSend = 'Send',
  textClose = 'Close',
}) => (
  <div className="modal-header" style={style1}>
    <Button.Button5 onClick={onSubmit} style={{ marginRight: 10 }}>
      {textSend}
    </Button.Button5>
    <Button.Button2 onClick={handleClose(id, onClose)}>
      {textClose}
    </Button.Button2>
  </div>
)

const styleBoxLoad = { position: 'relative', height: '80px' }

function handle(props) {
  const [isLoading, setIsLoading] = useState(false)
  const [fileName, setFileName] = useState()
  const [file, setFile] = useState()
  const [refInput, setRefInput] = useState('')

  const onSubmit = () => {
    if (!file || file.trim().length === 0) {
      swal(
        props.locale.ERROR,
        props.locale.WARNING_INVALID_INPUT + '!',
        'error'
      )
    } else {
      setIsLoading(true)
      props.onSubmit({ data_url: file }, () => {
        setIsLoading(false)
        onReset()
      })
    }
  }
  const onReset = () => {
    setFileName()
    setFile()
    setRefInput('')
  }

  const getBase64 = (dragFile, callback) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(dragFile)
  }

  const checkSupportFile = file => {
    if (file) {
      let name = file.name
      if (name.lastIndexOf('csv') > 0) {
        getBase64(file, url => {
          let type = 'data:text/csv;'
          setFileName(name)
          setFile(type + url.substring(url.indexOf('base64')))
        })
      } else if (name.lastIndexOf('xlsx') > 0) {
        getBase64(file, url => {
          let type =
            'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'
          setFileName(name)
          setFile(type + url.substring(url.indexOf('base64')))
        })
      } else {
        swal(props.locale.ERROR, props.locale.ERROR_UPLOAD_CSV_XLSX, 'error')
      }
    }
  }

  const handleDrop = dragFiles => checkSupportFile(dragFiles[0])
  const handleFileSeleted = event => checkSupportFile(event.target.files[0])
  const handleRefInput = ref => {
    if (ref && refInput !== ref) {
      setRefInput(ref)
    }
  }

  return {
    isLoading,
    onSubmit,
    onReset,
    fileName,
    handleDrop,
    handleFileSeleted,
    refInput,
    handleRefInput,
  }
}

function ImportInvitation(props) {
  const {
    isLoading,
    onSubmit,
    onReset,
    fileName,
    handleDrop,
    handleFileSeleted,
    refInput,
    handleRefInput,
  } = handle(props)

  return (
    <Modal id="modalImportInvitation">
      <ModalContent maxWidth={460}>
        <ModalHeader
          id="modalImportInvitation"
          title={props.locale.IMPORT_INVITATION}
          onClose={onReset}
        />

        <Body>
          {isLoading && (
            <div style={styleBoxLoad}>
              <Loading />
            </div>
          )}
          <ImportDetail lang={props.lang} locale={props.locale} />
          <DataTemplate />
          <DragNDropFile handleDrop={handleDrop}>
            <div
              style={{
                height: 200,
                width: 420,
                border: '2px dashed #707070',
                borderRadius: '6px',
                backgroundColor: 'rgba(255,255,255,.8)',
              }}
            >
              <div
                style={{
                  position: 'absolute',
                  bottom: '30%',
                  right: 0,
                  left: 0,
                  textAlign: 'center',
                }}
              >
                <div>
                  <DragNDropShowFileName fileName={fileName} />
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    onChange={handleFileSeleted}
                    ref={refInput => handleRefInput(refInput)}
                  />
                  <Button.Button5 onClick={() => refInput.click()}>
                    Browse
                  </Button.Button5>
                </div>
              </div>
            </div>
          </DragNDropFile>
        </Body>

        <ModalClose
          id="modalImportInvitation"
          onSubmit={onSubmit}
          onClose={onReset}
          textSend={props.locale.SEND}
          textClose={props.locale.CLOSE}
        />
      </ModalContent>
    </Modal>
  )
}

export default ImportInvitation

ImportInvitation.defaultProps = {
  onSubmit: null,
}
