import React, { useEffect, useState } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { navigate } from 'gatsby-link'
import queryString from 'query-string'
import moment from 'moment'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Loading from '../components/loading'

import authActions from '../state/auth/actions'
import homeActions from '../state/home/actions'
import invitationActions from '../state/invitation/actions'

import View from '../components/presentation/manage-event/invitations'
import ModalDetail from '../components/popup/invitation-detail'
import ModalNewInvitation from '../components/popup/invitation-new'
import ModalImportInvitation from '../components/popup/import-invitation'
import ModalExportInvitation from '../components/popup/export-invitation'
import UpgradePackage from '../components/box-create-events/box-create-events'
import ModalHandler from '../utils/modal'

// import JsonTree from '../components/json-tree'

function handle(props) {
  const params = queryString.parse(props.location.search)

  const [stateUI, setStateUI] = useState('MAIN')
  const [isLoading, setLoading] = useState(true)
  const [keyword, setKeyword] = useState('')
  const [keywordType, setKeywordType] = useState('receiver_name')

  useEffect(function() {
    props.checkAuthorization(null, '/', null, {
      onSuccess: () => {
        props.onLoad({
          id: params.id,
          search: {
            keyword,
            keywordType,
          },
          callback: () => {
            setLoading(false)
          },
        })
      },
    })
  }, [])

  function onSearch() {
    setLoading(true)
    props.onLoad({
      id: params.id,
      search: {
        keyword,
        keywordType,
      },
      callback: () => {
        setLoading(false)
      },
    })
  }

  function onChangePagination(offset) {
    setLoading(true)
    props.onLoad({
      id: params.id,
      search: {
        keyword: offset,
        keywordType: 'offset',
      },
      callback: () => {
        setLoading(false)
      },
    })
  }

  function onImportInvitation() {
    ModalHandler.openModal('modalImportInvitation')
  }

  function onExportInvitation() {
    ModalHandler.openModal('modalExportInvitation')
  }

  function onNewInvitation() {
    ModalHandler.openModal('modalInvitationNew')
  }

  function onSubmitNewInvitation(payload, callback) {
    props.sendInvitation(
      {
        event_id: parseInt(params.id),
        search: {
          keyword,
          keywordType,
        },
        ...payload,
      },
      callback
    )
  }

  function onKeyword(e) {
    if (e.currentTarget) {
      setKeyword(e.currentTarget.value)
    } else {
      setKeyword(e)
    }
  }

  function onKeywordType(e) {
    if (e.currentTarget.value === 'created_at') {
      const d = new Date()
      const form = moment(d).format('YYYY-MM-DDT00:00:00')
      const to = moment(d).format('YYYY-MM-DDT23:59:59')
      setKeyword({ form: form + '.00Z', to: to + '.00Z' })
    } else {
      setKeyword('')
    }
    setKeywordType(e.currentTarget.value)
  }

  function onClickTab(type) {
    if (type === 1) {
      navigate('/invitations/?id=' + params.id)
    } else if (type === 2) {
      navigate('/transactions/?id=' + params.id)
    } else if (type === 3) {
      navigate('/ticket-goods/?id=' + params.id)
    }
  }

  function onSubmitImportInvitation(payload, callback) {
    props.importInvitation(
      {
        event_id: parseInt(params.id),
        ...payload,
      },
      callback
    )
  }

  function onSubmitExportInvitation(payload, callback) {
    props.exportInvitation(
      {
        event_id: parseInt(params.id),
        ...payload,
      },
      callback
    )
  }

  function onClickRevoke(data) {
    props.revokeInvitation({ data })
  }

  function onClickResend(data) {
    props.resendInvitation({ data })
  }

  // function onExportData() {
  //   // window.open(`${process.env.GATSBY_API_ENDPOINT}/invitations?event_id=${params.id}&format=xlsx`, '_blank')
  //   window.open(`${process.env.GATSBY_API_ENDPOINT}/invitations?event_id=${params.id}&format=csv`, '_blank')
  // }

  // function onDownloadTemplateData() {
  //   window.open(`https://files-dev.invitree.me/templates/invitation_template.xlsx`, '_blank')
  //   window.open(`https://files-dev.invitree.me/templates/invitation_template.csv`, '_blank')
  // }

  let totalSummary = {
    event: {
      logo: '',
      title_en: '',
      title_th: '',
    },
    package: {
      name_en: '',
      name_th: '',
      currency: 'thb',
    },
    invitation_count: 0,
    joined_count: 0,
    max_invitations: 0,
    total_amount: '0',
  }

  if (props.state.totalSummary) {
    if (props.state.totalSummary.event) {
      totalSummary = props.state.totalSummary
    }
  }

  return {
    stateUI,
    isLoading,
    eventID: params.id,
    logo: totalSummary.event.logo
      ? totalSummary.event.logo + '?sz=m'
      : process.env.GATSBY_PLACEHOLDERS_IMAGE_EVENT_LOGO + '?sz=m',
    name:
      (props.app.lang === 'TH'
        ? totalSummary.event.title_th
        : totalSummary.event.title_en) || '',
    // packageID: totalSummary.package.id || 0,
    packagesName:
      (props.app.lang === 'TH'
        ? totalSummary.package.name_th
        : totalSummary.package.name_en) || '',
    sumInvitations: totalSummary.invitation_count,
    maxInvitations: totalSummary.max_invitations,
    sumJoined: totalSummary.joined_count,
    sumMoney: totalSummary.total_amount,
    currency: totalSummary.package.currency.toUpperCase(),
    dataList: props.state.invitationList,
    keyword,
    keywordType, // [Name,Email,Date,Status]
    onSearch,
    onChangePagination,
    onImportInvitation,
    onExportInvitation,
    onNewInvitation,
    onSubmitNewInvitation,
    onSubmitImportInvitation,
    onSubmitExportInvitation,
    onClickRevoke,
    onClickResend,
    onKeyword,
    onKeywordType,
    onClickTab,
    setStateUI,
  }
}

function InvitationsPage(props) {
  const {
    stateUI,
    isLoading,
    eventID,
    logo,
    name,
    // packageID,
    packagesName,
    sumInvitations,
    maxInvitations,
    sumJoined,
    sumMoney,
    currency,
    dataList,
    keyword,
    keywordType, // [Name,Email,Date,Status]
    onSearch,
    onChangePagination,
    onImportInvitation,
    onExportInvitation,
    onNewInvitation,
    onSubmitNewInvitation,
    onSubmitImportInvitation,
    onSubmitExportInvitation,
    onClickRevoke,
    onClickResend,
    onKeyword,
    onKeywordType,
    onClickTab,
    setStateUI,
  } = handle(props)

  if (isLoading) {
    return <Loading />
  }
  return (
    <Layout auth={props.auth.currentUser !== null}>
      <SEO keywords={[`invitree`]} />
      {stateUI === 'UPGRADE' ? (
        <div className="__header-box" id="owl-bg-red">
          <UpgradePackage
            title={props.app.locale[props.app.lang].UPGRADE_PACKAGE}
            textChoose={props.app.locale[props.app.lang].CHOOSE}
            data={props.app.packageForUpgrade || []}
            onChoose={packID => {
              props.onChooseEvent(packID, 'upgrade_package', eventID)
            }}
            locale={props.app.locale[props.app.lang]}
          />
        </div>
      ) : (
        <View
          eventID={eventID}
          logo={logo}
          name={name}
          packagesName={packagesName}
          sumInvitations={sumInvitations}
          maxInvitations={maxInvitations}
          sumJoined={sumJoined}
          sumMoney={sumMoney}
          currency={currency}
          data={dataList}
          onUpgrade={() => setStateUI('UPGRADE')}
          keyword={keyword}
          keywordType={keywordType}
          onSearch={onSearch}
          onChangePagination={onChangePagination}
          onImportInvitation={onImportInvitation}
          onExportInvitation={onExportInvitation}
          onNewInvitation={onNewInvitation}
          onClickRevoke={onClickRevoke}
          onClickResend={onClickResend}
          onKeyword={onKeyword}
          onKeywordType={onKeywordType}
          onClickTab={onClickTab}
          onDetail={props.onDetail}
          locale={props.app.locale[props.app.lang]}
        />
      )}
      <ModalDetail
        data={props.state.invitationDetail}
        locale={props.app.locale[props.app.lang]}
      />
      <ModalNewInvitation
        onSubmit={onSubmitNewInvitation}
        locale={props.app.locale[props.app.lang]}
      />
      <ModalImportInvitation
        onSubmit={onSubmitImportInvitation}
        lang={props.app.lang}
        locale={props.app.locale[props.app.lang]}
      />
      <ModalExportInvitation
        onSubmit={onSubmitExportInvitation}
        lang={props.app.lang}
        locale={props.app.locale[props.app.lang]}
      />
    </Layout>
  )
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    app: state.app,
    state: state.invitation,
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      checkAuthorization: authActions.checkAuthorization,
      sendInvitation: invitationActions.sendInvitation,
      importInvitation: invitationActions.importInvitation,
      exportInvitation: invitationActions.exportInvitation,
      revokeInvitation: invitationActions.revokeInvitation,
      resendInvitation: invitationActions.resendInvitation,
      onLoad: invitationActions.listInvitations,
      onDetail: invitationActions.onShowInvitationDetail,
      onChooseEvent: homeActions.onChooseEvent,
    },
    dispatch
  )
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvitationsPage)

/*
<JsonTree data={
{
  title: 'Invitations',
  props: { ...props },
  isLoading,
  logo,
  name,
  packagesName,
  sumInvitations,
  sumJoined,
  sumMoney,
  currency,
  dataList,
  keywordType,
  keyword,
}}/>
*/
