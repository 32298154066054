import React from 'react'
import { navigate } from 'gatsby-link'

import NoImage from '../../../images/no-preview.svg'
import Buttons from '../../form/buttons'
import ListView from '../../mobile/list-view-invitations'

import { ConvertISODate1, toUpperCaseFirst } from '../../../utils/string'
import Pagination from '../../form/pagination'

import {
  LogoImage,
  MobileBoxSummary,
  MobileHeadTitle,
  MobileMoneyBox,
  MobileMoneyTitle,
  MobileWrapper,
  PcBoxSummary,
  PcBoxWrapperSearch,
  PcMenuTabs,
  PCWrapper,
  SearchForm,
  styleMobileButtonNewInvitation,
  styleMobileButtonUpgrade,
  SummaryBoxItems,
  UpgradeBox,
} from './share'

const dropdownItems = locale => [
  { id: 'receiver_name', title: locale.NAME },
  { id: 'receiver_email', title: locale.EMAIL_ADDRESS },
  { id: 'created_at', title: locale.CREATED_DATE },
  { id: 'status', title: locale.STATUS },
]

const dynamicSearch = {
  status: [
    { id: 'created', title: 'Created' },
    { id: 'sent', title: 'Sent' },
    { id: 'joined', title: 'Joined' },
    { id: 'checked-in', title: 'Checked In' },
  ],
  created_at: true,
}

const onDetail = (fn, payload) => fn.bind(null, payload)
const onGoEdit = id => () => navigate(`/event-detail/edit/?id=${id}`)
const style1 = { fontWeight: 'bold' }
const style2 = { marginRight: 10 }
const style3 = { paddingTop: 5 }

function PcView(props) {
  let data = props.data.invitations || []

  return (
    <React.Fragment>
      <PCWrapper>
        <div>
          <LogoImage
            src={props.logo}
            alt=""
            onClick={onGoEdit(props.eventID)}
          />
        </div>
        <div style={style1}>{props.name}</div>
        <div>
          <PcBoxSummary>
            <SummaryBoxItems>
              <h5>{props.packagesName}</h5>
              <small>{props.locale.PACKAGE}</small>
            </SummaryBoxItems>
            <SummaryBoxItems>
              <h5>
                {props.sumInvitations} / {props.maxInvitations || 0}
              </h5>
              <small>{props.locale.INVITATIONS}</small>
            </SummaryBoxItems>
            <SummaryBoxItems>
              <h5>{props.sumJoined}</h5>
              <small>{props.locale.JOINED}</small>
            </SummaryBoxItems>
            <SummaryBoxItems>
              <h5>{props.sumMoney}</h5>
              <small>{props.currency}</small>
            </SummaryBoxItems>
          </PcBoxSummary>
        </div>
        <UpgradeBox>
          <Buttons.Button5
            onClick={props.onUpgrade}
            style={styleMobileButtonUpgrade}
          >
            {props.locale.UPGRADE}
          </Buttons.Button5>
        </UpgradeBox>
        <PcMenuTabs
          name="INVITATIONS"
          onClick={props.onClickTab}
          locale={props.locale}
        />
        <PcBoxWrapperSearch>
          <div>
            <SearchForm
              data={dropdownItems(props.locale)}
              dynamicSearch={dynamicSearch}
              keyword={props.keyword}
              keywordType={props.keywordType}
              onKeyword={props.onKeyword}
              onKeywordType={props.onKeywordType}
              onSearch={props.onSearch}
            />
          </div>
          <div style={style3}>
            <Buttons.Button3
              icon="fa fa-download"
              style={style2}
              onClick={props.onExportInvitation}
            />
            <Buttons.Button3
              icon="fa fa-upload"
              style={style2}
              onClick={props.onImportInvitation}
            />
            <Buttons.Button3
              icon="fa fa-plus"
              onClick={props.onNewInvitation}
            />
          </div>
        </PcBoxWrapperSearch>
        <div>
          <table className="transaction-table">
            <thead>
              <tr>
                <th>{props.locale.NAME}</th>
                <th>{props.locale.EMAIL_ADDRESS}</th>
                <th>{props.locale.CREATED_DATE}</th>
                <th>{props.locale.STATUS}</th>
                <th>{props.locale.ACTIONS}</th>
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((v, i) => {
                  let disabledBtn = v.status == 'join' || v.status == 'revoked'

                  return (
                    <tr key={`transaction-table-${i}`}>
                      <td>{v.receiver_name}</td>
                      <td>
                        <span
                          className="span-link"
                          onClick={onDetail(props.onDetail, v)}
                        >
                          {v.receiver_email}
                        </span>
                      </td>
                      <td>{ConvertISODate1(v.created_at)}</td>
                      <td>{toUpperCaseFirst(v.status)}</td>
                      <td>
                        <Buttons.Button2
                          disabled={disabledBtn}
                          onClick={() => props.onClickRevoke(v)}
                          style={{ padding: '0px', marginRight: '10px' }}
                        >
                          {props.locale.REVOKE}
                        </Buttons.Button2>
                        |
                        <Buttons.Button2
                          disabled={disabledBtn}
                          onClick={() => props.onClickResend(v)}
                          style={{ padding: '0px' }}
                        >
                          {props.locale.RESEND}
                        </Buttons.Button2>
                      </td>
                    </tr>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={4}>-- {props.locale.NO_DATA} --</td>
                </tr>
              )}
            </tbody>
          </table>

          <Pagination
            total={props.data.total_count}
            limit={props.data.limit}
            offset={props.data.offset}
            onChange={props.onChangePagination}
          />
        </div>
      </PCWrapper>
    </React.Fragment>
  )
}

function MobileView(props) {
  let data = props.data.invitations || []

  return (
    <MobileWrapper>
      <MobileHeadTitle>{props.name}</MobileHeadTitle>
      <MobileMoneyBox>
        <MobileMoneyTitle>
          {props.sumMoney} {props.currency}
        </MobileMoneyTitle>
      </MobileMoneyBox>
      <MobileBoxSummary>
        <SummaryBoxItems>
          <h5>{props.packagesName || '-'}</h5>
          <small>{props.locale.PACKAGE}</small>
        </SummaryBoxItems>
        <SummaryBoxItems>
          <h5>
            {props.sumInvitations || 0} / {props.maxInvitations || 0}
          </h5>
          <small>{props.locale.INVITATIONS}</small>
        </SummaryBoxItems>
        <SummaryBoxItems>
          <h5>{props.sumJoined || 0}</h5>
          <small>{props.locale.JOINED}</small>
        </SummaryBoxItems>
      </MobileBoxSummary>
      <UpgradeBox>
        <Buttons.Button5
          onClick={props.onUpgrade}
          style={styleMobileButtonUpgrade}
        >
          {props.locale.UPGRADE}
        </Buttons.Button5>
        <br />
        <Buttons.Button2
          noIcon
          onClick={props.onNewInvitation}
          style={styleMobileButtonNewInvitation}
        >
          {props.locale.NEW_INVITATION}
        </Buttons.Button2>
      </UpgradeBox>
      <PcMenuTabs name="INVITATIONS" onClick={props.onClickTab} isMobile />
      <ListView
        data={data}
        onClick={payload => {
          props.onDetail(payload)
        }}
      />

      <div style={{ textAlign: 'center' }}>
        <Pagination
          total={props.data.total_count}
          limit={props.data.limit}
          offset={props.data.offset}
          onChange={props.onChangePagination}
        />
      </div>
    </MobileWrapper>
  )
}

PcView.defaultProps = {
  eventID: 0,
  logo: NoImage,
  name: '',
  sumMoney: '0',
  currency: 'THB',
  packagesName: 'Free',
  sumInvitations: 0,
  maxInvitations: 0,
  sumJoined: 0,
  data: {},
  keyword: '',
  keywordType: '',
  onUpgrade: null,
  onNew: null,
  onSearch: null,
  onImportInvitation: null,
  onExportInvitation: null,
  onNewInvitation: null,
  onClickRevoke: null,
  onClickResend: null,
  onKeyword: null,
  onKeywordType: null,
  onClickTab: null,
  onDetail: null,
  locale: null,
}

MobileView.defaultProps = {
  eventID: 0,
  name: '',
  sumMoney: '0',
  currency: 'THB',
  packagesName: 'Free',
  sumInvitations: 0,
  maxInvitations: 0,
  sumJoined: 0,
  data: {},
  keyword: '',
  keywordType: '',
  onUpgrade: null,
  onNew: null,
  onSearch: null,
  onNewInvitation: null,
  onKeyword: null,
  onKeywordType: null,
  onClickTab: null,
  onDetail: null,
  locale: null,
}

export default props => (
  <div className="transaction-page">
    <MobileView
      eventID={props.eventID}
      name={props.name}
      packagesName={props.packagesName}
      sumInvitations={props.sumInvitations}
      maxInvitations={props.maxInvitations}
      sumJoined={props.sumJoined}
      sumMoney={props.sumMoney}
      currency={props.currency}
      data={props.data}
      keyword={props.keyword}
      keywordType={props.keywordType}
      onUpgrade={props.onUpgrade}
      onSearch={props.onSearch}
      onNewInvitation={props.onNewInvitation}
      onKeyword={props.onKeyword}
      onKeywordType={props.onKeywordType}
      onClickTab={props.onClickTab}
      onDetail={props.onDetail}
      locale={props.locale}
    />
    <PcView
      eventID={props.eventID}
      logo={props.logo}
      name={props.name}
      packagesName={props.packagesName}
      sumInvitations={props.sumInvitations}
      maxInvitations={props.maxInvitations}
      sumJoined={props.sumJoined}
      sumMoney={props.sumMoney}
      currency={props.currency}
      data={props.data}
      keyword={props.keyword}
      keywordType={props.keywordType}
      onUpgrade={props.onUpgrade}
      onSearch={props.onSearch}
      onChangePagination={props.onChangePagination}
      onImportInvitation={props.onImportInvitation}
      onExportInvitation={props.onExportInvitation}
      onNewInvitation={props.onNewInvitation}
      onClickRevoke={props.onClickRevoke}
      onClickResend={props.onClickResend}
      onKeyword={props.onKeyword}
      onKeywordType={props.onKeywordType}
      onClickTab={props.onClickTab}
      onDetail={props.onDetail}
      locale={props.locale}
    />
  </div>
)
