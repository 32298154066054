import React, { useState } from 'react'
import s from 'styled-components'
// import swal from 'sweetalert'

import { Modal, ModalContent, ModalHeader } from './index'
import Loading from '../loading'
import Button from '../form/buttons'
import ModalHandler from '../../utils/modal'

const Body = s.div`
  width: 100%;
  padding: 20px;
  text-align: justify;
`
const Table = s.table`
  width:100%;
  & > tbody > tr > td {
    padding: 10px;
    text-align: left;
  }
 `

const TdInput = s.td`
& > input , & > textarea , & > select{
  border: 1px solid #887979;
  border-radius: 6px;
  padding: 10px 10px;
  width: 100%;
}
, & > select{
  height: 45px;
  background: #ffffff;
}
`

const style1 = { textAlign: 'right', borderTop: '1px solid #E8E8E8' }
const handleClose = (id, onClose) => {
  return () => {
    ModalHandler.closeModal(id)
    if (onClose) {
      onClose()
    }
  }
}

export const ModalClose = ({
  id,
  onSubmit,
  onClose,
  textDownload = 'Download',
  textClose = 'Close',
}) => (
  <div className="modal-header" style={style1}>
    <Button.Button5 onClick={onSubmit} style={{ marginRight: 10 }}>
      {textDownload}
    </Button.Button5>
    <Button.Button2 onClick={handleClose(id, onClose)}>
      {textClose}
    </Button.Button2>
  </div>
)

const styleBoxLoad = { position: 'relative', height: '80px' }

function handle(props) {
  const [isLoading, setIsLoading] = useState(false)
  const [extension, setExtenstion] = useState('XLSX')

  const onChangeExtension = e => setExtenstion(e.currentTarget.value)

  const onSubmit = () => {
    setIsLoading(true)
    props.onSubmit({ extension: extension.toLowerCase() }, () => {
      setIsLoading(false)
      onReset()
    })
  }

  const onReset = () => {
    setExtenstion('XLSX')
  }

  return {
    isLoading,
    extension,
    onChangeExtension,
    onSubmit,
    onReset,
  }
}

function ExportInvitation(props) {
  const { isLoading, extension, onChangeExtension, onSubmit, onReset } = handle(
    props
  )

  return (
    <Modal id="modalExportInvitation">
      <ModalContent maxWidth={400}>
        <ModalHeader
          id="modalExportInvitation"
          title={props.locale.EXPORT_INVITATION}
          onClose={onReset}
        />

        <Body>
          {isLoading && (
            <div style={styleBoxLoad}>
              <Loading />
            </div>
          )}
          <Table>
            <tbody>
              <tr>
                <TdInput>
                  {props.locale.FILE_EXTENSION}:
                  <select value={extension} onChange={onChangeExtension}>
                    <option>XLSX</option>
                    <option>CSV</option>
                  </select>
                </TdInput>
              </tr>
            </tbody>
          </Table>
        </Body>

        <ModalClose
          id="modalExportInvitation"
          onSubmit={onSubmit}
          onClose={onReset}
          textDownload={props.locale.DOWNLOAD}
          textClose={props.locale.CLOSE}
        />
      </ModalContent>
    </Modal>
  )
}

export default ExportInvitation

ExportInvitation.defaultProps = {
  onSubmit: null,
}
