import React, { Component } from 'react'

class DragNDropFile extends Component {
  constructor(props) {
    super(props)

    this.dragCounter = 0
    this.state = { dragging: false }
  }

  setPreventAndPropagation = event => {
    event.preventDefault()
    event.stopPropagation()
  }

  handleDrag = event => {
    this.setPreventAndPropagation(event)
  }

  handleDragIn = event => {
    this.setPreventAndPropagation(event)

    if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
      this.dragCounter++
      this.setState({ dragging: true })
    }
  }

  handleDragOut = event => {
    this.setPreventAndPropagation(event)

    if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
      this.dragCounter--
      if (this.dragCounter === 0) {
        this.setState({ dragging: false })
      }
    }
  }

  handleDrop = event => {
    this.setPreventAndPropagation(event)

    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      this.props.handleDrop(event.dataTransfer.files)
      event.dataTransfer.clearData()

      this.setState({ dragging: false })
      this.dragCounter = 0
    }
  }

  render() {
    return (
      <div
        style={{ position: 'relative' }}
        onDragEnter={this.handleDragIn}
        onDragLeave={this.handleDragOut}
        onDragOver={this.handleDrag}
        onDrop={this.handleDrop}
      >
        {this.state.dragging && (
          <div
            style={{
              border: '2px dashed #C14C40',
              borderRadius: '6px',
              backgroundColor: 'rgba(255,255,255,.8)',
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 9999,
            }}
          />
        )}
        {this.props.children}
      </div>
    )
  }
}

export default DragNDropFile
